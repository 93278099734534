import React from 'react';
import { HashLink } from 'react-router-hash-link';

const teamMembers = [
  { name: 'Neel', role: 'Broker', image: 'neel.png' },
  { name: 'Savanna', role: 'Product', image: 'savanna.png' },
  { name: 'Thomas', role: 'Education', image: 'thomas.png' },
  { name: 'Micala', role: 'Marketing', image: 'micala.png' },
  { name: 'Frank', role: 'Technology', image: 'frank.png' },
  { name: 'Oscar', role: 'Technology', image: 'oscar.png' },
  { name: 'Neer', role: 'Data', image: 'neer.png' },
  { name: 'Jeff', role: 'Strategy', image: 'jeff1.png' }
];

const gptArmy = [
  { name: 'Val', role: 'Home Value Expert', image: 'Val.png' },
  { name: 'Grace', role: 'Home Search Assistant', image: 'grace.png' },
  { name: 'Blair', role: 'Home Improvement Advisor', image: 'blair.png' },
  { name: 'Jamie', role: 'DIY Handyman', image: 'jamie.png' },
  { name: 'Aisha', role: 'Real Estate Study Advisor', image: 'aisha.png' },
  { name: 'Elijah', role: 'Global Real Estate Coach', image: 'elijah-suit.png' },
  { name: 'Flip', role: 'House Flipping Helper', image: 'flip.png' },
  { name: 'Elena', role: 'Assisted Living Advocate', image: 'elena.png' },
  { name: 'Sarah', role: 'Rental Specialist', image: 'sarah.png' },
  { name: 'Samuel', role: 'Probate Specialist', image: 'samuel.png' },
  { name: 'Kai', role: 'Community Guide', image: 'kai.png' },
  { name: 'Tasha', role: 'College Prep Coach', image: 'tasha.png' },
  { name: 'Marrium', role: 'Wedding Architect', image: 'marrium.png' },
  { name: 'Sandy', role: 'Business Automater', image: 'sandy.png' },
  { name: 'Coach Reece', role: 'Real Estate Coach', image: 'reece.png' },
  { name: 'Aiden', role: 'Data Miner', image: 'aiden.png' },
];
//  { name: 'Brice', role: 'Listing Assistant', image: 'brice.png' },

const TeamPage = () => {
  return (
    <main id="team" className="container mt-5">
      <h1>Our Leadership Team</h1>
      <p>Imagine a powerhouse team with over 175 years of collective expertise spanning Real Estate, AI, Education, Product Development, Engineering, and Technology. The leaders of Cloud Team Realty are not just industry veterans; they are trailblazers who have held senior leadership positions at prestigious companies such as Homesmart, Wells Fargo, General Motors, Microsoft and Best Buy.</p>
      <p>United by a shared vision, they have come together with a singular mission: to revolutionize the real estate experience. Through their innovative and proven system, Cloud Team Realty is dedicated to giving people back one of the most valuable commodities – their time. This isn’t just a goal; it’s a mission that drives every decision, every strategy, and every interaction.</p>
      <p><HashLink smooth to='/careers#openroles'>Join us</HashLink> on this transformative journey and experience how the combined brilliance of Cloud Team Realty’s leaders can redefine your real estate expectations and deliver unparalleled results and outsized gross commission returns.</p>
      <div className="row">
        {teamMembers.map(member => (
          <div key={member.name} className="col-lg-3 col-md-4 col-sm-6 mb-4">
            <div className="card text-bg-dark text-center">
              <img src={member.image} className="card-img-top" alt={member.name} />
              <div className="card-body">
                <h5 className="card-title">{member.name}</h5>
                <p className="card-text text-center">{member.role}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <h2 className="mb-4">Our Infinitely Scalable AI Army</h2>
      <div className="row">
        {gptArmy.map(member => (
          <div key={member.name} className="col-lg-3 col-md-4 col-sm-6 mb-4">
            <div className="card text-bg-dark text-center">
              <img src={member.image} className="card-img-top" alt={member.name} />
              <div className="card-body">
                <h5 className="card-title">{member.name}</h5>
                <p className="card-text text-center">{member.role}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="agent" id="agents">
        <img src="Val.png" width="100px" align="left" alt="Val, the Home Value Expert" />
        <h3>Val, the Home Value Expert</h3>
        <p>Val is the go-to expert for estimating home valuations and CMA's. Leveraging cutting-edge algorithms and comprehensive realtime market data, Val provides consumers with property assessments, ensuring they have the insights needed to make informed real estate decisions.</p>
        <p><a href="https://val.cloudteamtx.com" target='_blank' rel="noreferrer" className="btn btn-success">Chat with Val for FREE now</a></p><br/>
    </div>
    <div className="agent" id="agents">
        <img src="grace.png" width="100px" align="left" alt="Grace, the Home Search Assistant" />
        <h3>Grace, the Home Search Assistant</h3>
        <p>Grace is a dedicated home search assistant designed to help people find homes for sale with ease. With access to listings on all 50 states MLS systems, Grace offers customized searches, detailed property insights, and expert guidance every step of the way. Discover your dream home through a personalized and seamless experience.</p>
        <p><a href="https://grace.cloudteamtx.com" target='_blank' rel="noreferrer" className="btn btn-success">Chat with Grace for FREE now</a></p><br/>
    </div>
    <div className="agent" id="agents">
        <img src="blair.png" width="100px" align="left" alt="Blair, the Home Improvement Advisor" />
        <h3>Blair, the Home Improvement Advisor</h3>
        <p>Blair is your go-to expert for enhancing home value through smart improvements. Combining design expertise with market insights, Blair provides personalized advice on updates and renovations to maximize your property’s worth. From budget planning to contractor recommendations, Blair ensures your projects boost both your living experience and investment returns.</p>
        <p><a href="https://blair.cloudteamtx.com" target='_blank' rel="noreferrer" className="btn btn-success">Chat with Blair for FREE now</a></p><br/>
    </div>
    <div className="agent" id="agents">
        <img src="jamie.png" width="100px" align="left" alt="Jamie, Your DIY Handyman" />
        <h3>Jamie, Your DIY Handyman</h3>
        <p>Jamie is your go-to for DIY home projects & repairs. With expertise in many tasks, he offers high-level advice, tips, and step-by-step instructions for you. From fixing leaky faucets to installing new appliances, he guides you safely & efficiently with cost-effective solutions.</p>
        <p><a href="https://jamie.cloudteamtx.com" target='_blank' rel="noreferrer" className="btn btn-success">Chat with Jamie for FREE now</a></p><br/>
    </div>
    <div className="agent" id="agents">
        <img src="aisha.png" width="100px" align="left" alt="Aisha, the Real Estate Study Advisor" />
        <h3>Aisha, the Real Estate Study Advisor</h3>
        <p>Your ultimate study companion for aspiring and current Realtors. Aisha helps you organize study schedules, find resources, and track progress, ensuring you stay on top of training and Continuing Education credit needs.</p>
        <p><a href="https://aisha.cloudteamtx.com" target='_blank' rel="noreferrer" className="btn btn-success">Chat with Aisha for FREE now</a></p><br/>
    </div>
    <div className="agent" id="agents">
        <img src="elijah-suit.png" width="100px" align="left" alt="Elijah, the Global Real Estate Coach" />
        <h3>Elijah, the Global Real Estate Coach</h3>
        <p>Your Global Real Estate Transactions coach who is an international property specialist. He guides REALTORS® through global property deals. Focusing on international laws, market trends, and cultural nuances, Elijah ensures smooth cross-border transactions. For official resources, visit https://www.nar.realtor/</p>
        <p><HashLink smooth to='/careers#openroles'  className="btn btn-primary"> Join the Cloud Team Today so you can chat with Elijah! </HashLink></p><br/>
    </div>
    <div className="agent" id="agents">
        <img src="flip.png" width="100px" align="left" alt="Flip, the House Flipping Helper" />
        <h3>Flip, the House Flipping Helper</h3>
        <p>Flip can provide comprehensive support to house flippers, helping them make informed decisions, manage projects effectively, and achieve their flipping goals.  He can calculate After Rehab Value, Return on Investment, renovation costs based on photos of the house and analyze market data to help flippers determine if a home is a good deal for them.</p>
        <p><a href="https://flip.cloudteamtx.com" target='_blank' rel="noreferrer" className="btn btn-success">Chat with Flip for FREE now</a></p><br/>
    </div>
    <div className="agent" id="agents">
        <img src="elena.png" width="100px" align="left" alt="Elena, the Assisten Living Advocate" />
        <h3>Elena, the Assisted Living Advocate</h3>
        <p>Elena is your friendly senior living advocate. She helps seniors find the perfect assisted living resources tailored to their needs, ensuring comfort and peace of mind.</p>
        <p><HashLink smooth to='/careers#openroles'  className="btn btn-primary"> Join the Cloud Team Today so you can chat with Elena! </HashLink></p><br/>
    </div>
    <div className="agent" id="agents">
        <img src="sarah.png" width="100px" align="left" alt="Sarah, the Rental Specialist" />
        <h3>Sarah, the Rental Specialist</h3>
        <p>Sarah is a dedicated rental advisor with realtime market data. She can help renters find their ideal new home, whether it’s renting a cozy apartment, spacious house, modern condo, or purchasing a new home. She offers personalized assistance, neighborhood insights, and expert financial guidance.
        </p>
        <p><a href="https://sarah.cloudteamtx.com" target='_blank' rel="noreferrer" className="btn btn-success">Chat with Sarah for FREE now (For a limited time only!)</a></p><br/>
    </div>
    <div className="agent" id="agents">
        <img src="samuel.png" width="100px" align="left" alt="Samuel, the Probate Specialist" />
        <h3>Samuel, the Probate Specialist</h3>
        <p>Samuel helps people navigate estate settlements, manage assets, and handle legalities with compassion and expertise. He helps make a difficult journey smoother.</p>
        <p><a href="https://samuel.cloudteamtx.com" target='_blank' rel="noreferrer" className="btn btn-success">Chat with Samuel for FREE now (For a limited time only!)</a></p><br/>
    </div>
    <div className="agent" id="agents">
        <img src="kai.png" width="100px" align="left" alt="Kai, the Community Guide" />
        <h3>Kai, the Community Guide</h3>
        <p> Kai is your professional vlogger / neighborhood guide! Get insights on local schools, amenities, crime rates, and community vibes for your desired area. Kai helps you make informed real estate decisions on where you might want to live</p>
        <p><a href="https://kai.cloudteamtx.com" target='_blank' rel="noreferrer" className="btn btn-success">Chat with Kai for FREE now</a></p><br/>
    </div>
    <div className="agent" id="agents">
        <img src="tasha.png" width="100px" align="left" alt="Tasha, the College Prep Coach" />
        <h3>Tasha, the College Prep Coach</h3>
        <p>Tasha is the ultimate guide for high school seniors and families, offering personalized advice on applications, scholarships, financial aid, and campus life.  Navigate the college transition smoothly with expert support and resources from her.</p>
        <p><HashLink smooth to='/careers#openroles'  className="btn btn-primary"> Join the Cloud Team Today so you can chat with Tasha! </HashLink></p><br/>
    </div>
    <div className="agent" id="agents">
        <img src="marrium.png" width="100px" align="left" alt="Marrium, the Wedding Architect" />
        <h3>Marrium, the Wedding Architect</h3>
        <p> Marrium is your ultimate wedding planner, here to assist with every detail from venues to vows. Get personalized recommendations, manage your guest list, and stay on budget with ease. Let’s make your dream wedding a reality!</p>
        <p><a href="https://marrium.cloudteamtx.com" target='_blank' rel="noreferrer" className="btn btn-success">Chat with Marrium for FREE now</a></p><br/>
    </div>
    {/* <div className="agent" id="agents">
        <img src="brice.png" width="100px" align="left" alt="Brice, the Listing Assistant" />
        <h3>Brice, the Listing Assistant</h3>
        <p>Brice is your dedicated Real Estate Listing Assistant. Brice specializes in streamlining communication and qualifying potential buyers, ensuring that your time is spent only on serious and capable candidates. With a professional yet personable approach, Brice helps make the selling process efficient and effective, acting as a reliable liaison between you and the buyer’s agent or buyer.</p>
        <p><a href="https://brice.cloudteamtx.com" target='_blank' rel="noreferrer" className="btn btn-success">Chat with Brice now, if you are a REALTOR®</a></p><br/>
    </div> */}
    {/* <div className="agent" id="agents">
        <h3>Sarah, the Buyer Agents Assistant</h3>
        <p><br/><br/>
        <a href="https://sarah.cloudteamtx.com" target='_blank' rel="noreferrer" className="btn btn-success">Chat with Sarah if you are a REALTOR®.  You will need an account.</a>
        </p>
    </div> */}
    <div className="agent">
        <img src="sandy.png" width="100px" align="left" alt="Sandy, the Executive Assistant for REALTORS®"/>
        <h3>Sandy, the Executive Assistant for REALTORS®</h3>
        <p>As an executive assistant for real estate professionals, Sandy streamlines a REALTORS® workflow by handling tasks like contact management, data organization and transaction coordination. With her support, you can focus more on closing deals and building relationships.</p>
        <p><HashLink smooth to='/careers#openroles'  className="btn btn-primary"> Join the Cloud Team Today so you can chat with Sandy! </HashLink></p><br/>
    </div>
    <div className="agent">
        <img src="reece.png" width="100px" align="left" alt="Coach Reece, the Real Estate Coach" />
        <h3>Coach Reece, the Real Estate Coach</h3>
        <p>Coach Reece is dedicated to enhancing a REALTORS® real estate prowess. Offering personalized coaching and strategic guidance, Reece helps REALTORS® refine their skills, boost their productivity, and achieve their business goals with confidence and clarity.</p>
        <p><HashLink smooth to='/careers#openroles'  className="btn btn-primary"> Join the Cloud Team Today so you can talk with Coach Reece! </HashLink></p><br/>
    </div>
    <div className="agent">
        <img src="aiden.png" width="100px" align="left" alt="Aiden, the Data Miner" />
        <h3>Aiden, the Data Miner</h3>
        <p>Aiden is the analytical powerhouse of our team. Specializing in data mining and analysis, Aiden uncovers valuable market trends and insights, empowering REALTORS® with the information they need to stay ahead of the competition and make data-driven decisions.  Aiden can do everything Val can, but with greater power, data, and precision, as he is specifically designed for REALTORS® rather than the general public.</p>
        <p><HashLink smooth to='/careers#openroles'  className="btn btn-primary"> Join the Cloud Team Today so you can work with Aiden! </HashLink></p><br/>
    </div>
    <p>Together, our AI Agent Army is here to revolutionize the real estate industry, providing unparalleled support, expertise, and insights to both consumers and REALTORS® alike.</p>
    </main>
  );
};

export default TeamPage;
