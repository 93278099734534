import React from 'react';
import './CareersPage.css';

const TermsOfServicePage = () => {
  return (
    <main id="terms-of-service" className="container mt-5">
      <h1 className="mb-4">Terms of Service</h1>
      <section className="mb-5">
        <h2 className="mb-3">1. Introduction</h2>
        <p>
          Welcome to Cloud Team Realty, Inc. These Terms of Service ("Terms") govern your use of our website and services. By accessing or using our website, you agree to be bound by these Terms.
        </p>
      </section>
      <section className="mb-5">
        <h2 className="mb-3">2. Use of Our Service</h2>
        <p>
          You must follow any policies made available to you within our services. You may use our services only as permitted by law. We may suspend or stop providing our services if you do not comply with our terms or policies.
        </p>
      </section>
      <section className="mb-5">
        <h2 className="mb-3">3. Your Account</h2>
        <p>
          You may need an account to use some of our services. You are responsible for safeguarding your account and keeping your information accurate. You are responsible for the activity that happens on or through your account.
        </p>
      </section>
      <section className="mb-5">
        <h2 className="mb-3">4. Privacy and Copyright Protection</h2>
        <p>
          Cloud Team Realty, Inc.'s privacy policies explain how we treat your personal data and protect your privacy when you use our services. By using our services, you agree that Cloud Team Realty, Inc. can use such data in accordance with our privacy policies.
        </p>
      </section>
      <section className="mb-5">
        <h2 className="mb-3">5. Modifying and Terminating Our Services</h2>
        <p>
          We are constantly changing and improving our services. We may add or remove functionalities or features, and we may suspend or stop a service altogether. You can stop using our services at any time, although we'll be sorry to see you go.
        </p>
      </section>
      <section className="mb-5">
        <h2 className="mb-3">6. Our Warranties and Disclaimers</h2>
        <p>
          We provide our services using a commercially reasonable level of skill and care, but there are certain things that we don’t promise about our services.
        </p>
      </section>
      <section className="mb-5">
        <h2 className="mb-3">7. Liability for Our Services</h2>
        <p>
          When permitted by law, Cloud Team Realty, Inc. will not be responsible for lost profits, revenues, or data, financial losses, or indirect, special, consequential, exemplary, or punitive damages.
        </p>
      </section>
      <section className="mb-5">
        <h2 className="mb-3">8. Business Uses of Our Services</h2>
        <p>
          If you are using our services on behalf of a business, that business accepts these terms. It will hold harmless and indemnify Cloud Team Realty, Inc. and its affiliates, officers, agents, and employees from any claim, suit or action arising from or related to the use of the services or violation of these terms, including any liability or expense arising from claims, losses, damages, suits, judgments, litigation costs and attorneys’ fees.
        </p>
      </section>
      <section className="mb-5">
        <h2 className="mb-3">9. About These Terms</h2>
        <p>
          We may modify these terms or any additional terms that apply to a service to reflect changes to the law or changes to our services. You should look at the terms regularly. We will post notice of modifications to these terms on this page.
        </p>
        <p>
          If you do not agree to the modified terms for a service, you should discontinue your use of that service.
        </p>
      </section>
    </main>
  );
};

export default TermsOfServicePage;