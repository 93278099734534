import React from 'react';
import { HashLink } from 'react-router-hash-link';

const SystemPage = () => {
  return (
    <main id="platform" className="container mt-5">
      <h1>How We Give Back Time</h1>
      <div className="row">
        <div className="col-md-6 mb-4">
          <div className="card h-100 border-primary text-bg-dark mb-3">
            <div className="card-body">
              <h2 className="card-title mb-3">Unified Solutions for Success</h2>
              <p className="card-text">
              Our AI-first, cohesive system seamlessly combines all essential tools and resources into one unified platform. This system is designed to streamline every aspect of real estate management, ensuring that all components work together harmoniously. From property listings and client management to marketing and transaction coordination, our cohesive system provides a comprehensive, interconnected approach that enhances efficiency, reduces redundancy, and supports smooth, successful operations. Enjoy a seamless experience where every part of a REALTORS’® business is synchronized for optimal performance.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-6 mb-4">
          <div className="card h-100 border-success text-bg-dark  mb-3">
            <div className="card-body">
              <h2 className="card-title mb-3">Artificial Intelligence Agents</h2>
              <p className="card-text">
              Alongside our platform, REALTORS® can leverage our AI agents to further enhance their productivity and efficiency. These AI agents assist with various tasks, making the process even more streamlined and infinitely scalable. Whether it’s managing communications, scheduling, or data analysis, our AI agents ensure REALTORS® can focus on what they do best – closing deals and building client relationships.<br/><br/>
                <HashLink smooth to='/team#agents'  className="btn btn-primary"> Meet the AI agents </HashLink>
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-6 mb-4">
          <div className="card h-100 border-warning text-bg-dark  mb-3">
            <div className="card-body">
              <h2 className="card-title mb-3">Advanced Marketing Engines</h2>
              <p className="card-text">
              REALTORS® who are sponsored by Cloud Team Realty, Inc. not only benefit from our integrated AI platform but also receive leads from our advanced online and offline marketing engines at no additional cost. This ensures a steady stream of potential clients, allowing agents to focus on the people part of the business. Our comprehensive marketing strategies, which include digital advertising, social media campaigns, and traditional marketing methods, are designed to maximize exposure and attract high-quality leads, supporting REALTORS® in growing their business effectively and efficiently.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-6 mb-4">
          <div className="card h-100 border-info text-bg-dark mb-3">
            <div className="card-body">
              <h2 className="card-title mb-3">Empowering REALTORS® to Succeed</h2>
              <p className="card-text">
              We are dedicated to helping REALTORS® grow and thrive in their careers. We offer a variety of educational programs and technical training designed to enhance their skills and expand their knowledge. Whether they’re new to the industry or looking to advance their expertise, our courses cover everything from market trends and technology to client relations and business strategies. Join our community of professionals committed to excellence and continuous learning.
              <br/><br/><HashLink smooth to='/careers#openroles'  className="btn btn-primary"> Join the Cloud Team Today! </HashLink>
              </p>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default SystemPage;
