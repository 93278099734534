import React from 'react';
import './CareersPage.css';

const FairHousing = () => {
  return (
    <main id="fairhousing" className="container mt-5">
      <h1>Fair Housing Statement</h1>
      <section className="mb-4">
        <p className="lead">Cloud Team Realty is committed to providing an inclusive and welcoming environment for all individuals. We fully support the principles of the Fair Housing Act, which prohibits discrimination in housing based on race, color, religion, sex, national origin, disability, or familial status.</p>
<p className="lead">As licensed real estate professionals, Cloud Team Texas is dedicated to ensuring that all potential buyers, sellers, renters, and landlords are treated with respect and fairness throughout their real estate transactions. We are committed to upholding the highest standards of integrity and professionalism in all our interactions.</p>
<p className="lead">If you believe you have experienced discrimination in any aspect of the real estate transaction, we encourage you to report the incident to the U.S. Department of Housing and Urban Development (HUD) at 1-800-669-9777 or through their website at <a href="https://www.hud.gov/program_offices/fair_housing_equal_opp/complaint-process">HUD's complaint page</a>.</p>
<p className="lead">We value diversity and inclusivity and strive to ensure that every client receives equal housing opportunities.</p>
<h2 className="mb-3">Equal Housing Opportunity</h2>       
      </section>
    </main>
  );
};

export default FairHousing;