import React from 'react';
import './CareersPage.css';

const PrivacyPolicyPage = () => {
  return (
    <main id="privacy-policy" className="container mt-5">
      <h1 className="mb-4">Privacy Policy</h1>
      <section className="mb-5">
        <h2 className="mb-3">1. Introduction</h2>
        <p>
          Cloud Team Realty, Inc. ("we", "our", "us") is committed to protecting and respecting your privacy. This policy (together with our terms of service and any other documents referred to on it) sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us.
        </p>
      </section>
      <section className="mb-5">
        <h2 className="mb-3">2. Information We Collect</h2>
        <p>
          We collect information to provide better services to all our users. We collect information in the following ways:
          <ul>
            <li>Information you give us. For example, our services require you to sign up for an account. When you do, we’ll ask for personal information, like your name, email address, and telephone number.</li>
            <li>Information we get from your use of our services. We collect information about the services that you use and how you use them, like when you visit our website or interact with our content.</li>
          </ul>
        </p>
      </section>
      <section className="mb-5">
        <h2 className="mb-3">3. How We Use Information We Collect</h2>
        <p>
          We use the information we collect from all our services to provide, maintain, protect and improve them, to develop new ones, and to protect Cloud Team Realty, Inc. and our users. We also use this information to offer you tailored content.
        </p>
      </section>
      <section className="mb-5">
        <h2 className="mb-3">4. Information We Share</h2>
        <p>
          We do not share personal information with companies, organizations, and individuals outside of Cloud Team Realty, Inc. unless one of the following circumstances applies:
          <ul>
            <li>With your consent. We will share personal information with companies, organizations, or individuals outside of Cloud Team Realty, Inc. when we have your consent to do so.</li>
            <li>For legal reasons. We will share personal information with companies, organizations, or individuals outside of Cloud Team Realty, Inc. if we have a good-faith belief that access, use, preservation, or disclosure of the information is reasonably necessary to meet any applicable law, regulation, legal process, or enforceable governmental request.</li>
          </ul>
        </p>
      </section>
      <section className="mb-5">
        <h2 className="mb-3">5. Information Security</h2>
        <p>
          We work hard to protect Cloud Team Realty, Inc. and our users from unauthorized access to or unauthorized alteration, disclosure, or destruction of information we hold. We restrict access to personal information to Cloud Team Realty, Inc. employees, contractors, and agents who need to know that information in order to process it for us and who are subject to strict contractual confidentiality obligations.
        </p>
      </section>
      <section className="mb-5">
        <h2 className="mb-3">6. Changes to This Policy</h2>
        <p>
          Our Privacy Policy may change from time to time. We will not reduce your rights under this Privacy Policy without your explicit consent. We will post any privacy policy changes on this page and, if the changes are significant, we will provide a more prominent notice.
          </p>
      </section>
      <section className="mb-5">
        <h2 className="mb-3">7. Children's Privacy</h2>
        <p>
          Our services are not intended for use by children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have collected personal information from a child under age 13 without verification of parental consent, we take steps to remove that information from our servers.
        </p>
      </section>
      <section className="mb-5">
        <h2 className="mb-3">8. Your Rights</h2>
        <p>
          You have the right to access, update, and delete your personal information. You can exercise these rights by contacting us at <a href="mailto:privacy@cloudteamtx.com">privacy@cloudteamtx.com</a>.
        </p>
      </section>
      <section className="mb-5">
        <h2 className="mb-3">9. Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact us at:
          <br />
          Cloud Team Realty, Inc.
          <br />
          Email: <a href="mailto:privacy@cloudteamtx.com">privacy@cloudteamtx.com</a>
          <br />
          Address: 251 SW Wilshire Blvd. #124-527 Burleson, TX 76028
        </p>
      </section>
    </main>
  );
};

export default PrivacyPolicyPage;
