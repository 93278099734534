import React from 'react';
import './MainPage.css'; 

const MainPage = () => {
  return (
    <main id="main">
      <section className="hero">
        <div className="hero-content">
          <h1>Changing REALTORS® lives so they can do more of what they love.</h1>
          <p>We are an AI first real estate brokerage located in Texas. We specialize in educating people on how to maximize their time by utilizing our fully-integrated end-to-end system that optimizes real estate transactions from <i>marketing</i> to <i>close</i>.</p>
          <div className="hero-buttons">
            <a href="#why-section" className="btn btn-primary">Learn more</a>
            <a href="/agents" className="btn btn-light">Meet the REALTORS®</a> 
            <a href="https://cloudteamrealty.com/" target="_blank" className="btn btn-success">Find a Property</a> 
          </div>
        </div>
      </section>
      <section id="why-section"> 
      <div className="container">
  <div className="row">
  <div className="col"></div>
    <div className="col-12"> 
    <div className="card text-bg-dark">
  
  <div className="card-body">
  <div className="card-title display-5">
    We are on a mission.
  </div>
    <p className="card-text ">
        <small>We believe in transforming lives by giving people back their time.
        We aim to do that by giving real estate agents, brokers, and their clients 90% of their time back so that they can focus on the things that matter most to them.
        The way we do that is by building, delivering & teaching our cutting-edge AI first solutions.</small>
    </p>
    <a href="/system" className="btn btn-primary">How we give back time</a><br/><a href="/careers" className="btn btn-success">Get involved</a> 
  </div>
</div>
    
   </div>
   <div className="col"></div>
  </div>
</div>
       
      </section>
    </main>
  );
};

export default MainPage;
