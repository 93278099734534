import React from 'react';
import { Link } from 'react-router-dom';

const trainingWeeks = [
  { week: 1, title: 'Onboarding & Tool Proficiency', description: 'Setting up brokerage tools, establishing your online presence, and initiating outreach.' },
  { week: 2, title: 'Foundation in Lead Generation & CRM', description: 'Mastering CRMs, CRM labels, and social media engagement.' },
  { week: 3, title: 'Marketing Strategies & AI Integration', description: 'Implementing AI tools, creating marketing content, and increasing outreach.' },
  { week: 4, title: 'Advanced Outreach & Client Interaction', description: 'Enhancing marketing impact and refining phone negotiation skills.' },
  { week: 5, title: 'Transaction Management & Client Engagement', description: 'Learning transaction managaement tool basics and managing client expectations.' },
  { week: 6, title: 'Deep Dive into Contracts & Negotiations', description: 'Strengthening contract handling and negotiation skills.' },
  { week: 7, title: 'Handling Active Transactions & Client Negotiations', description: 'Navigating real client scenarios and improving closing tactics.' },
  { week: 8, title: 'Closing Deals & Advanced Transaction Management', description: 'Managing multiple transactions and refining client relationships.' },
  { week: 9, title: 'Reflection & Goal Setting', description: 'Reviewing progress and planning for continued growth.' },
  { week: 10, title: 'Mastery Through Volume & Closing Preparations', description: 'Applying the Law of 100s to master outreach and prepare for closings.' },
  { week: 11, title: 'Finalizing Transactions & Celebrating Success', description: 'Ensuring client satisfaction and preparing for independent work.' },
  { week: 12, title: 'Graduation & Launch Preparation', description: 'Reviewing achievements, setting future goals, and receiving your official “Ticket to Launch.”' }
];

const TicketToLaunchPage = () => {
  return (
    <main id="training-program" className="container mt-5">
      <h1>🚀 Welcome to Cloud Team's 12-Week "Ticket to Launch" Program! 🎉</h1>
      <p>
        We're committed to empowering our agents with the tools and strategies needed for success. Our <strong>12-week onboarding program</strong> is designed to equip you with essential skills, guided by expert coaches and AI tools to ensure you excel in real estate in modern times. 🌟📈
      </p>
      <p>
        This program is more than just training—it's your launchpad to mastering your craft and thriving in the real estate market!  Whether you're a new or transitioning agent, we provide a comprehensive roadmap to elevate your skills and business.
      </p>
      
      <h2>12-Week Program Overview 🗓</h2>
      <div className="row">
        {trainingWeeks.map((week) => (
          <div key={week.week} className="col-lg-4 col-md-6 col-sm-12 mb-4">
            <div className="card text-bg-dark">
              <div className="card-body">
                <h5 className="card-title">Week {week.week}: {week.title}</h5>
                <p className="card-text">{week.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      
      <h2>Why Join the "Ticket to Launch" Program? 🤖</h2>
      <p>
        Our program integrates <strong>AI-powered tools</strong> such as chatGPT for personalized coaching, lead generation, and automated outreach, making your journey smooth and efficient. Each task is pre-loaded into your CRM (that we provide you), allowing you to track your progress and stay on top of your activities seamlessly.
      </p>
      <ul>
        <li>🔹 Structured weekly roadmap to guide your growth</li>
        <li>🔹 Activity-based learning for hands-on experience</li>
        <li>🔹 Role-play scenarios to build real-world confidence</li>
        <li>🔹 AI-driven marketing and outreach tools</li>
      </ul>
      <h2>What does Ticket To Launch look like?</h2>
      <p>
      <div id="carouselExample" class="carousel slide">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src="Ticket To Launch Activity Sample.png" class="d-block w-100" alt="Week 1 Activities for a Realtor"></img>
    </div>
    <div class="carousel-item">
      <img src="Ticket To Launch Report Sample.png" class="d-block w-100" alt="Live Reporting Showing Your Progress"></img>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
      </p>
      <h2>Ready to Elevate Your Real Estate Career? 🚀</h2>
      <p>
        If you're ready to take your real estate career to the next level, join Cloud Team Realty and gain access to our exclusive "Ticket to Launch" program.
      </p>
      <p><Link to='/contact' className="btn btn-primary">Join Cloud Team Today!</Link></p>
    </main>
  );
};

export default TicketToLaunchPage;