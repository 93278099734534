import React from 'react';
import './CareersPage.css';

const CareersPage = () => {
  return (
    <main id="careers" className="container mt-5">
      <h1>Careers</h1>
      
      <section className="mb-4">
        <h2 className="mb-3">Our Culture</h2>
        <p className="lead">
          At Cloud Team Realty, Inc., we believe in transforming lives by giving people back their time. We are dedicated to revolutionizing the real estate industry through cutting-edge solutions that empower real estate agents, brokers, and their clients. Our team is passionate, innovative, and committed to excellence. We value integrity, collaboration, and a relentless pursuit of improvement.
        </p>
      </section>
      
      <section className="mb-4">
        <h2 className="mb-3">Why Join Us?</h2>
        <ul className="list-group list-group-flush">
          <li className="list-group-item">Be part of a dynamic and forward-thinking company</li>
          <li className="list-group-item">Work with a talented, high-performing, and supportive team</li>
          <li className="list-group-item">Opportunities for professional growth and development</li>
          <li className="list-group-item">Flexible working environment</li>
          <li className="list-group-item">Competitive compensation</li>
        </ul>
      </section>
      
      <section className="mb-4" id="openroles">
        <h2 className="mb-3">Open Positions</h2>
        <p className="lead">
          We are always looking for talented individuals to join our team. If you are passionate about real estate and technology, and want to make a significant impact, we would love to hear from you.
        </p>
        <ul className="list-group list-group-flush">
          <li className="list-group-item">Realtors®</li>
          <li className="list-group-item">Educators</li>
          <li className="list-group-item">AI Software Developers</li>
          <li className="list-group-item">Data Analysts</li>
          <li className="list-group-item">Marketing Specialists</li>
          <li className="list-group-item">Customer Success Managers</li>
        </ul>
        <p className="mt-3">
          Please send your resume to <a href="mailto:careers@cloudteamtx.com">careers@cloudteamtx.com</a>.
        </p>
      </section>
      
      <section className="mb-4">
        <h2 className="mb-3">Our Hiring Process</h2>
        <p className="lead">
          Our hiring process is designed to ensure that we find the right fit for our team and our company culture. It typically includes:
        </p>
        <ol className="list-group list-group-numbered">
          <li className="list-group-item">Application Review</li>
          <li className="list-group-item">Initial Phone Interview</li>
          <li className="list-group-item">Technical/Skill Assessment</li>
          <li className="list-group-item">On-site or Virtual Interview</li>
          <li className="list-group-item">Offer and Onboarding</li>
        </ol>
      </section>
    </main>
  );
};

export default CareersPage;