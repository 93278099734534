import React from 'react';

const Footer = () => {
  return (
    <footer className="footer mt-auto py-3 bg-dark">
      <div className="container">
        <ul className="list-inline">
          <li className="list-inline-item"><a href="/team">Leadership Team</a></li>
          <li className="list-inline-item"><a href="/agents">Our REALTORS®</a></li>
          <li className="list-inline-item"><a href="/system">How we give back time</a></li>
          <li className="list-inline-item"><a href="/ticket-to-launch">Ticket To Launch</a></li>
          <li className="list-inline-item"><a href="/careers">Careers</a></li>
          <li className="list-inline-item"><a href="/getlicensed">Get Licensed</a></li>
          <li className="list-inline-item"><a href="/contact">Contact Us</a></li>
          <li className="list-inline-item"><a href="/privacy">Privacy Policy</a></li>
          <li className="list-inline-item"><a href="/terms">Terms of Service</a></li>
        </ul>
        <p className="copyright">
        <a href="https://cdn.cloudteamtx.com/listing_materials/IABS-CTR-JL.pdf" target='_new'>TREC Information About Brokerage Services</a> | <a href="https://www.trec.texas.gov/sites/default/files/pdf-forms/CN%201-5_0.pdf" target='_new'>TREC Consumer Protection Notice</a> | <a href="https://cdn.cloudteamtx.com/logos/trec-disclaimer.pdf" target='_new'>TREC Disclaimer</a><br/>
The REALTORS® of Cloud Team are licensed Texas Real Estate Agents for Cloud Team Realty, Inc.<br/>
&copy; 2024 Cloud Team Realty, Inc.  All rights reserved. <br/><a href="/fairhousing"><img src="https://cdn.cloudteamtx.com/logos/fair-housing-transparent.png" height="50" alt='Fair Housing'/></a></p>
      </div>
    </footer>
  );
};

export default Footer;