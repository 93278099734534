
import React from 'react';

const GetLicensedPage = () => {
  return (
    <main id="team" className="container mt-5 text-light">
      <h1 className="bodyAlign mb-4">How to Become a REALTOR® in Texas</h1>
      <p className="mb-5 bodyAlign">
      Interested in becoming a REALTOR®? Here’s everything you need to know, from becoming a licensed agent, to becoming one of the most engaged and professional REALTORS® in the nation.</p>
      <h2 className="bodyAlign mb-4">Cover the Basics: Become an Agent</h2>
      <p className="bodyAlign mb-5">Before you can be a REALTOR®, you've got to become a licensed Texas real estate agent. Licensee requirements vary from state to state, but all of the necessary steps are outlined below.
      </p>

      <section className="mb-5">
        <h2 className="mb-4 text-uppercase border-bottom pb-2">Step-by-Step Guide</h2>
        <div className="card bg-secondary text-white shadow-sm mb-4">
          <div className="card-body bodyAlign">
            <h5 className="card-title font-weight-bold">Step 1: Meet Basic Eligibility Requirements</h5>
            <ul className="mb-0 getLicensedLI">
              <li>Be at least 18 years old.</li>
              <li>Be a U.S. citizen or a lawfully admitted alien.</li>
              <li>Meet Texas Real Estate Commission (TREC) honesty, trustworthiness, and integrity standards.</li>
            </ul>
          </div>
        </div>

        <div className="card bg-secondary text-white shadow-sm mb-4">
          <div className="card-body bodyAlign">
            <h5 className="card-title font-weight-bold">Step 2: Complete Required Pre-Licensing Education</h5>
            <p>Complete 180 hours of TREC-approved coursework:</p>
            <ul class="getLicensedLI">
              <li>Principles of Real Estate I (30 hours)</li>
              <li>Principles of Real Estate II (30 hours)</li>
              <li>Law of Agency (30 hours)</li>
              <li>Law of Contracts (30 hours)</li>
              <li>Promulgated Contract Forms (30 hours)</li>
              <li>Real Estate Finance (30 hours)</li>
            </ul>
            <p className="mb-0"><strong>Cost Averages:</strong> $250 - $1,000 (varies by provider).</p>
            <div>For official TREC qualifying courses and providers, please visit the TREC Approved Qualifying Real Estate Courses below.</div>
            <a href="https://www.trec.texas.gov/education/approved-qualifying-real-estate-courses" target='_blank' className="btn btn-info mt-3">Click here to View the TREC Approved Courses</a>
          </div>
        </div>

        <div className="card bg-secondary text-white shadow-sm mb-4">
          <div className="card-body bodyAlign">
            <h5 className="card-title font-weight-bold">Step 3: Submit Your Application to TREC</h5>
            <ul className="mb-0 getLicensedLI">
              <li>Apply online through the TREC website.</li>
              <li>Pay the application fee: <strong>$185</strong>.</li>
            </ul>
            <a href="https://mylicense.trec.texas.gov/datamart/registration.do?from=loginPage" target='_blank' class="btn btn-success mt-3">Click here to File a New TREC Application</a>
          </div>
        </div>

        <div className="card bg-secondary text-white shadow-sm mb-4">
          <div className="card-body bodyAlign">
            <h5 className="card-title font-weight-bold">Step 4: Complete a Background Check and Fingerprinting</h5>
            <p>Schedule an appointment through IdentoGO, TREC’s approved vendor.</p>
            <p className="mb-0"><strong>Cost:</strong> $38.25</p>
            <a href="https://www.trec.texas.gov/fingerprint-requirements" target='_blank' class="btn btn-warning mt-3">Click here to Start the Background Check</a>
          </div>
        </div>

        <div className="card bg-secondary text-white shadow-sm mb-4">
          <div className="card-body bodyAlign">
            <h5 className="card-title font-weight-bold">Step 5: Pass the Real Estate Exam</h5>
            <ul class="getLicensedLI">
              <li>Register with Pearson VUE to take the Texas Real Estate Salesperson Exam.</li>
              <li>Exam Fee: <strong>$43</strong>.</li>
              <li>Optional Exam Prep Materials: <strong>$50 - $200</strong>.</li>
            </ul>
            <a href="/practice-test-bot" className="btn btn-info mt-3">Click here to take AI powered Practice Tests FREE</a>
          </div>
        </div>

        <div className="card bg-secondary text-white shadow-sm mb-4">
          <div className="card-body bodyAlign">
            <h5 className="card-title font-weight-bold">Step 6: Find a Sponsoring Broker</h5>
            <p>To activate your license, you must work under a licensed Texas real estate broker.</p>
            <p className="mb-0">
              <strong>We recommend Cloud Team Realty</strong> for exceptional training and support.
            </p>
            <a href="/system" className="btn btn-primary mt-3">Click here to see what Cloud Team offers You</a>
          </div>
        </div>

        <div className="card bg-secondary text-white shadow-sm mb-4">
          <div className="card-body bodyAlign">
            <h5 className="card-title font-weight-bold">Step 7: Activate Your License</h5>
            <p>Once you've passed your exam and selected Cloud Team Realty as your broker, activate your license through TREC.</p>
            <a href="https://mylicense.trec.texas.gov/datamart/login.do" target="_blank" className="btn btn-warning mt-3">Click here to Activate Your License!</a>
          </div>
        </div>

        <div className="card bg-secondary text-white shadow-sm mb-4">
          <div className="card-body bodyAlign">
            <h5 className="card-title font-weight-bold ">Step 8: Begin Ticket To Launch</h5>
            <p>Congratulations! You’ve completed the initial steps to become a licensed Texas REALTOR®. Now it’s time to take your career to the next level with the Ticket To Launch Program. This step is designed to equip you with the tools, training, and mentorship needed to hit the ground running as a real estate professional.</p>
            <a href="/ticket-to-launch" class="btn btn-success mt-3">Learn more about the Ticket To Launch</a>
          </div>
        </div>

        <h2 className="mb-4 text-uppercase border-bottom pb-2">Summary of Costs</h2>
        <table className="table table-bordered table-hover text-white bg-dark shadow-sm">
          <thead className="bg-secondary text-dark">
            <tr className="bg-secondary text-dark">
              <th className="bg-secondary text-light">Step</th>
              <th className="bg-secondary text-light">Estimated Cost</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Pre-Licensing Education</td>
              <td>$250 - $1,000</td>
            </tr>
            <tr>
              <td>TREC Application Fee</td>
              <td>$185</td>
            </tr>
            <tr>
              <td>Fingerprinting</td>
              <td>$38.25</td>
            </tr>
            <tr>
              <td>Exam Fee</td>
              <td>$43</td>
            </tr>
            <tr>
              <td>Exam Prep (optional)</td>
              <td>$50 - $200</td>
            </tr>
            <tr>
              <td><strong>Total</strong></td>
              <td><strong>$516.25 - $1,466.25</strong></td>
            </tr>
          </tbody>
        </table>
      </section>
    </main>
  );
};

export default GetLicensedPage;
