import React, { useState } from 'react';

const ContactUsPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://flowapi.cloudteamtx.com/webhook/corp-contactus', {
        method: 'POST',
        //mode:'no-cors',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        //console.log('Form submitted:', formData);
        setIsSubmitted(true);
        setIsError(false);
        // Optionally, clear the form after submission
        setFormData({
          name: '',
          email: '',
          phone: '',
          message: ''
        });
      } else {
        console.error('Error submitting form');
        setIsSubmitted(false);
        setIsError(true);
      }
    } catch (error) {
      console.error('Error submitting form', error);
      setIsSubmitted(false);
      setIsError(true);
    }
  };

  return (
    <main id="contact" className="container mt-5">
      <h1>Contact Us</h1>
      <p><i className="fa fa-phone"></i> Phone: 888-687-1001</p>
      <p><i className="fa fa-envelope"></i> Email: contact@cloudteamtx.com</p>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label className="form-label">Name:</label>
          <input
            type="text"
            className="form-control"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Email:</label>
          <input
            type="email"
            className="form-control"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Phone:</label>
          <input
            type="tel"
            className="form-control"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Message:</label>
          <textarea
            className="form-control"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <button type="submit" className="btn btn-primary">Submit</button>
      </form>
      {isSubmitted && !isError && <div className="alert alert-success mt-3">Form submitted successfully!</div>}
      {isError && <div className="alert alert-danger mt-3">There was an error submitting the form. Please try again.</div>}
    </main>
  );
};

export default ContactUsPage;
